
























































import Vue from "vue";
import { Action } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import { required, email } from "vuelidate/lib/validators";

import api from "@/core/utils/api";

@Component({
  mixins: [validationMixin],
  validations: {
    emailInput: { required, email },
  },
})
export default class ForgotPassword extends Vue {
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;

  emailInput = "";
  get emailErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.emailInput!.$dirty) return errors;
    !this.$v.emailInput!.email &&
      errors.push(this.$t("forgotPassSection.invalidEmail"));
    !this.$v.emailInput!.required &&
      errors.push(this.$t("forgotPassSection.emailRequired"));
    return errors;
  }

  loading = false;
  sent = false;
  async submit() {
    this.$v.$touch();
    if (this.loading) return;

    if (this.$v.$invalid) {
      const msg = this.$t("forms.invaliform").toString();
      this.displaySnackbar(msg);
      return;
    }

    this.loading = true;
    try {
      const end = `/api/Account/MakePasswordReset?email=${this.emailInput}`;
      await api.get(end);
      const msg = this.$t("snack.auth.sent").toString();
      this.displaySnackbar(msg);
      this.sent = true;
      setTimeout(() => (this.sent = false), 10 * 1000);
    } catch (error) {
      const { description } = error;
      const msg = this.$t("snack.auth.sendfail").toString();
      this.displaySnackbar(description || msg);
    }
    this.loading = false;
  }

  beforeDestroy() {
    this.emailInput = "";
  }
}
